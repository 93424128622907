
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PaystubsDocumentPage',

  components: {
    DocumentsList: defineAsyncComponent(() => import('@/components/account/Documents/DocumentsList.vue')),
    PDEMessageBox: defineAsyncComponent(() => import('@/components/pde/PDEMessageBox.vue')),
    PDESkeleton: defineAsyncComponent(() => import('@/components/pde/PDESkeleton.vue')),
    PDESvg: defineAsyncComponent(() => import('@/components/pde/PDESvg.vue')),
  },

  data: () => ({
    analyticsPaystubs: {
      download: {
        name: 'Download Click',
        text: 'Paystubs',
        area: 'documents/financial/paystubs',
      },
      expand: {
        name: 'See more paystubs',
        text: 'See more',
        area: 'documents/financial-documents',
      },
      open: {
        name: 'Preview Click',
        text: 'Paystubs',
        area: 'documents/financial/paystubs',
      },
    },
  }),

  computed: {
    ...mapGetters('account', ['division']),
    ...mapGetters('documents', ['isLoading', 'payStubs']),
    ...mapState('account', ['userDetailsLoaded']),

    payrollPhoneNumbers() {
      return this.$t(`myteams.jobnames.divisions.${this.division}.general`).find(contact => contact.name === 'Payroll')
    },
  },
}
